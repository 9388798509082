<template>
  <div v-if="products">
    <div>
      <h1 style="line-height: 3rem">
        <p>
          {{
            $t(
              `${
                hasFavorites ? "favourites.preferred" : "favourites.bestselling"
              }`
            )
          }}
        </p>
      </h1>
      <div class="text-body-2 default--text">
        <p>Sono stati trovati {{ products.length }} prodotti</p>
      </div>
    </div>
    <div style="max-height:100%" v-if="products.length > 0">
      <v-row>
        <v-col
          cols="12"
          sm="3"
          lg="3"
          v-for="product in products"
          :key="product.productId"
        >
          <ProductCard v-bind:product="product" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import ProductCard from "@/components/product/ProductCard.vue";
import ProductService from "~/service/productService";

import deliveryReactive from "~/mixins/deliveryReactive";

import { mapGetters } from "vuex";

export default {
  name: "ListPreferred",
  data() {
    return {
      products: []
    };
  },
  components: {
    ProductCard
  },
  mixins: [deliveryReactive],
  computed: {
    ...mapGetters({
      hasFavorites: "cart/hasFavorites"
    }),
    crmLayout() {
      return this.hasFavorites ? 29 : 26;
    }
  },
  methods: {
    async addAllToCart() {},
    async fetchProducts() {
      let response = await ProductService.adv({
        layout: this.crmLayout,
        limit: 50
      });
      this.products = response.products;
    }
  },
  mounted() {
    this.fetchProducts();
  }
};
</script>
